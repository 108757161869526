import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DesitnationListComponent } from './components/desitnation-list/desitnation-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    LoginComponent,
    DesitnationListComponent,
    ToastComponent
  ],
  imports: [
    CommonModule
    , RouterModule
    , ReactiveFormsModule
  ],
  exports: [
    NotFoundComponent,
    ToastComponent
  ]
})
export class SharedModule { }
