import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavbarService {
    toggleNavSub = new Subject();
    NavColorSub = new Subject<boolean>();
    ToDisplayTransparentNav = new Subject<boolean>();
    Url = new Subject<string>();
    TourPackage = new Subject<boolean>();

    DisplayWhiteNav() {
        this.NavColorSub.next(true);
    }

    DisplayTransparentNav() {
        this.ToDisplayTransparentNav.next(true);
    }

    HideTransparentNav() {
        this.ToDisplayTransparentNav.next(false);
    }

    DisplayNormalNav() {
        this.NavColorSub.next(false);
    }
}
