import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl  } from '@angular/forms';
import { ContactUsService } from 'src/app/shared/services/contact-us.service';
import { Title } from '@angular/platform-browser';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss']
})
// tslint:disable-next-line: component-class-suffix
export class ContactPage implements OnInit {
  contactForm: FormGroup;
  ShowAlert: boolean = false;
  AlertText: string = '';
  ErrorBg: string = '';
  constructor(private fb: FormBuilder
            , private contactusService: ContactUsService
            , private title: Title
            , private titleService: TitleService) { }

  ngOnInit() {
    this.title.setTitle(`Contact | ` + this.titleService.siteName );
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: [],
      email: ['', [ Validators.required, Validators.email]],
      phone: ['', [ Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(15), Validators.minLength(10) ]],
      message: ['', [ Validators.minLength(4),, Validators.maxLength(1000)]]
    });
  }

  onReset() {
    this.ShowAlert = false;
    this.AlertText = '';
  }

  onSubmit(e: any) {
    // const data = {
    //   FullName: 'Sahil Sharda',
    //   Phone: '1234567890',
    //   Email: 'dummy@email.com',
    //   Query: 'Test Query'
    // };

    e.preventDefault();
    if (this.contactForm.invalid) {
      this.validateAllFormFields(this.contactForm);
      return ; }

    const contactDetail = this.contactForm.getRawValue();

    this.contactusService.Send(contactDetail)
      .subscribe((res: any) => {
        console.log(res);
        this.ShowAlert = true;
        this.AlertText = res;
        this.ErrorBg = 'green';
        this.contactForm.reset();
      }, err => {
        console.log(err);
        this.ShowAlert = true;
        this.AlertText = 'Fail to send email.';
        this.ErrorBg = 'red';
      });
  }

  validateAllFormFields(formGroup: FormGroup) {       //{1}
  Object.keys(formGroup.controls).forEach(field => {  //{2}
    const control = formGroup.get(field);             //{3}
    if (control instanceof FormControl) {             //{4}
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {        //{5}
      this.validateAllFormFields(control);            //{6}
    }
  });

  
}

}
