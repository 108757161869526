import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContactUs } from '../models/contact-us.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContactUsService {
    // basePath = `http://localhost:58149/`;
    // basePath = `http://localhost:81/`;
    // basePath = `http://himapi.magictymhost.com/`;
    basePath = `https://api.navratnaholidays.com/`
    constructor(private http: HttpClient) {}

    Send(details: ContactUs): Observable<any> {
        // return this.http.post<any>('http://localhost:62787/send', details, {
        // return this.http.post<any>('http://localhost:53130/send', details, {
        return this.http.post<any>(`${this.basePath}api/ContactUs/ContactUs`, details, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }
}
