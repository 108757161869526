import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AngularMatModule } from '../shared/angular-mat.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { WebsiteModule } from '../modules/website/website.module';
import { DestinationModule } from '../modules/destination/destination.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule
    , CoreRoutingModule
    , AngularMatModule
    , ReactiveFormsModule
    , MatButtonModule
    , MatCheckboxModule
    , MatAutocompleteModule
    , WebsiteModule
    , DestinationModule
    , SharedModule
  ]
  ,
  exports: [
    HomeComponent
    , CoreRoutingModule
  ]
})
export class CoreModule { }
