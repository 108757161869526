import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl  } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TitleService } from '../../services/title.service';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { Login } from '../../models/Login.model';
import { ToastService } from '../toast/toast.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  ShowAlert = false;
  AlertText = '';
  ErrorBg = '';
constructor(  private fb: FormBuilder
            , private title: Title
            , private titleService: TitleService
            , private apiService: ApiService
            , private router: Router
            , private toast: ToastService
            , private session: SessionService
    ) { }

  ngOnInit() {
    this.title.setTitle(`Login | ` + this.titleService.siteName );
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onReset() {
    this.ShowAlert = false;
    this.AlertText = '';
  }

  onSubmit(e: any) {
    e.preventDefault();
    if (this.loginForm.invalid) {
      this.validateAllFormFields(this.loginForm);
      return ; }

    const Detail = this.loginForm.getRawValue();

    this.apiService.Login(new Login({
      Username: Detail.username,
      Password: Detail.password
    })).subscribe((res: any) => {
        if (res.status) {
          this.router.navigateByUrl('/destinationlist');
        } else {
          this.toast.Show('Sorry!', res.message, 2000);
          this.session.Close();
        }
      }, err => {
          this.toast.Show('Sorry!', 'Something went wrong.', 2000);
      });
  }

  validateAllFormFields(formGroup: FormGroup) {       // {1}
    Object.keys(formGroup.controls).forEach(field => {  // {2}
      const control = formGroup.get(field);             // {3}
      if (control instanceof FormControl) {             // {4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        // {5}
        this.validateAllFormFields(control);            // {6}
      }
    });
  }
}
