import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Destination } from 'src/app/shared/models/Destination.model';
import { Title } from '@angular/platform-browser';
import { TitleService } from 'src/app/shared/services/title.service';
import { DataService } from 'src/app/shared/services/data.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-destination',
  templateUrl: './destination.page.html',
  styleUrls: ['./destination.page.scss']
})
export class DestinationPage implements OnInit, OnDestroy {

  public Destination: Destination;
  private dataServiceSub = new Subscription();
  constructor(
      private route: ActivatedRoute
    , private title: Title
    , private titleService: TitleService
    , private dataService: DataService
  ) {}

  ngOnInit() {
    const snapId = this.route.snapshot.params['id'];
    this.dataServiceSub = this.dataService.Destinationsfilled.subscribe(val => {
      this.Update(snapId);
    });
    this.Update(snapId);
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.Update(id);
    });
    $('.tabgroup > div').hide();
    $('.tabgroup > div:first-of-type').show();
    $('.tabs a').click(function(e: any) {
      e.preventDefault();
      let $this = $(this),
      tabgroup = '#' + $this.parents('.tabs').data('tabgroup'),
      others = $this.closest('li').siblings().children('a'),
      target = $this.attr('href');
      others.removeClass('active');
      $this.addClass('active');
      $(tabgroup).children('div').hide();
      $(target).show();
    });
    this.title.setTitle( this.Destination.Heading + ` | ` + this.titleService.siteName );
  }

  ngOnDestroy() {
    this.dataServiceSub.unsubscribe();
  }

  private Update(snapId: any) {
    if (snapId != null) {
      this.Destination = this.dataService.GetDestinationById(+snapId);
      // this.titleService.setTitle(`${this.series.Name} | Muscle Science`);
    }
  }
}
