import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Renderer, Renderer2 } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Subscription } from 'rxjs';

import { NavbarService } from '../../navbar.service';
import { Router, NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true}) sideNav: MatSidenav;
  @ViewChild('contactDetail', {static: false}) contactDetail: ElementRef;
  @ViewChild('welcomeDetail', {static: false}) welcomeDetail: ElementRef;
  toggleNavSubs: Subscription;
  isStopScrolling = false;
  isIndexPage = false;
  constructor(private navService: NavbarService
            , private router: Router
            , private platLoc: PlatformLocation
            , private el: ElementRef
            , private renderer: Renderer2) {
    platLoc.onPopState(() => {
      this.isStopScrolling = true;
    });
  }

  ngOnInit() {
    this.toggleNavSubs =  this.navService.toggleNavSub.subscribe(() => {
      this.sideNav.toggle();
    });
    this.navService.Url.next(this.router.url);
    if (this.router.url.indexOf('index') > -1 || this.router.url.indexOf('/') === this.router.url.length - 1 ) {
      this.isIndexPage = true;
    } else {
      this.isIndexPage = false;
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        this.navService.Url.next(this.router.url);
        if (this.router.url.indexOf('index') > -1 || this.router.url.indexOf('/') === this.router.url.length - 1 ) {
          this.isIndexPage = true;
        } else{
          this.isIndexPage = false;
        }
        return;
      }
      if (this.isStopScrolling) {
        this.isStopScrolling = false;
        return;
      }
      window.scrollTo({
        top: 0,
        left: 0
        // behavior: 'smooth'
      });
    });
  }

  ngOnDestroy() {
    this.toggleNavSubs.unsubscribe();
  }

  openDetails(e: Event) {
    this.renderer.setStyle(this.contactDetail.nativeElement, 'bottom', '60px' ) ;
    this.renderer.setStyle(this.contactDetail.nativeElement, 'right', '60px' ) ;
    this.renderer.setStyle(this.contactDetail.nativeElement, 'opacity', '1' ) ;
  }

  closeDetails(e: Event) {
    this.renderer.setStyle(this.contactDetail.nativeElement, 'bottom', '-100%' ) ;
    this.renderer.setStyle(this.contactDetail.nativeElement, 'right', '-100%' ) ;
    this.renderer.setStyle(this.contactDetail.nativeElement, 'opacity', '0' ) ;
  }

  openWelcomeDetails(e: Event) {
    this.renderer.setStyle(this.welcomeDetail.nativeElement, 'bottom', '60px' ) ;
    this.renderer.setStyle(this.welcomeDetail.nativeElement, 'left', '60px' ) ;
    this.renderer.setStyle(this.welcomeDetail.nativeElement, 'opacity', '1' ) ;
  }

  closeWelcomeDetails(e: Event) {
    this.renderer.setStyle(this.welcomeDetail.nativeElement, 'bottom', '-100%' ) ;
    this.renderer.setStyle(this.welcomeDetail.nativeElement, 'left', '-100%' ) ;
    this.renderer.setStyle(this.welcomeDetail.nativeElement, 'opacity', '0' ) ;
  }
}
