import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DestinationRoutingModule } from './destination-routing.module';
import { DestinationPage } from './pages/destination/destination.page';
import { DestinationsPage } from './pages/destinations/destinations.page';

@NgModule({
  declarations: [
  DestinationPage
  , DestinationsPage
],
  imports: [
    CommonModule,
    DestinationRoutingModule
  ]
  ,
  exports: [
    DestinationRoutingModule
  ]
})
export class DestinationModule { }
