import { NgModule } from '@angular/core';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
// tslint:disable-next-line: max-line-length
import { MatSidenavModule, MatIconModule, MatToolbarModule, MatListModule, MatButtonModule, MatInputModule, MatFormFieldModule, MatSelectModule } from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    BrowserAnimationsModule
    , MatSidenavModule
    , MatIconModule
    , MatToolbarModule
    , MatListModule
    , MatButtonModule
    , MatFormFieldModule
    , MatInputModule
    , MatSelectModule
  ],
  exports: [
    BrowserAnimationsModule
    , MatSidenavModule
    , MatIconModule
    , MatToolbarModule
    , MatListModule
    , MatButtonModule
    , MatFormFieldModule
    , MatInputModule
    , MatSelectModule
  ]
})
export class AngularMatModule {}
