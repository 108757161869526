import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { DestinationModule } from './modules/destination/destination.module';
import { WebsiteModule } from './modules/website/website.module';
import { HttpClientModule } from '@angular/common/http';
import { LowerCaseUrlSerializer } from './shared/models/LowerCaseSerializer';
import { UrlSerializer } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ParallaxDirective } from './shared/directives/parallax.directive';

@NgModule({
  declarations: [
    AppComponent
    , ParallaxDirective
  ],
  imports: [
    BrowserModule
    , CoreModule
    , SharedModule
    , WebsiteModule
    , DestinationModule
    , AppRoutingModule
    , HttpClientModule
    , ReactiveFormsModule
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
