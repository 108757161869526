import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DestinationPage } from './pages/destination/destination.page';
import { DestinationsPage } from './pages/destinations/destinations.page';

const routes: Routes = [
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DestinationRoutingModule { }
