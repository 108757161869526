import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleService } from 'src/app/shared/services/title.service';
import { DataService } from 'src/app/shared/services/data.service';
import { Place } from 'src/app/shared/models/Place.model';
import { Subscription, Subject } from 'rxjs';
import { NavbarService } from 'src/app/core/navbar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Destination } from 'src/app/shared/models/Destination.model';
declare var $: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss']
})
export class IndexPage implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  public places: Array<Place> = [];
  public hometourpackages: Array<Destination> = [];
  IsLoading = true;
  IsViewInit = false;
  IsDataReady = false;
  IsShowGallery = false;
  IsCardHoverApplied = false;
  @ViewChild('tourpackage', {static: true}) tourpackage: ElementRef;
  constructor(
      private title: Title
    , private titleService: TitleService
    , private dataService: DataService
    , private navService: NavbarService
    , private router: Router
    , private activatedRoute: ActivatedRoute) { }

  GalleryLoaded = new Subject<boolean>();
  PlacesFilledSubc: Subscription;
  DestFilledSubc: Subscription;
  GalleryLoadedSubc: Subscription;
  ngOnInit() {
    this.title.setTitle(`Index | ` + this.titleService.siteName );
    this.GetDomesticPlaces();
    this.GetTourPackages();
    this.cardHover();
    this.GalleryLoadedSubc = this.GalleryLoaded.subscribe(d => {
      setTimeout(() => {
        this.initSlider();
        this.IsShowGallery = true;
      }, 100);
    });
    this.PlacesFilledSubc = this.dataService.Placesfilled.subscribe(val => {
      this.GetDomesticPlaces();
      this.IsDataReady = true;
      if (this.IsViewInit && this.IsDataReady) {
        this.GalleryLoaded.next();
      }
      this.GetTourPackages();
      this.cardHover();
    });
    this.DestFilledSubc = this.dataService.Destinationsfilled.subscribe(val => {
      this.GetTourPackages();
      this.cardHover();
    });
    if(this.places !== null && this.places.length > 0) {
      this.dataService.Placesfilled.next(true);
    }
    this.navService.TourPackage.subscribe(val => {
    });
    if (this.activatedRoute.snapshot.fragment) {
      this.tourpackage.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
    this.navService.TourPackage.subscribe(val => {
      if (this.activatedRoute.snapshot.fragment) {
        this.tourpackage.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }
    });
  }

  ngOnChanges() { }

  ngOnDestroy() {
    this.PlacesFilledSubc.unsubscribe();
    this.GalleryLoadedSubc.unsubscribe();
    this.DestFilledSubc.unsubscribe();
  }

  ngAfterViewInit() {
    this.IsViewInit = true;
    if (this.IsViewInit && this.IsDataReady) {
      this.GalleryLoaded.next();
    }
  }

  private initSlider() {
    const gal = $('.gallery');
    if (gal == null || gal.length < 1) { return; }
    gal.flickity({
      freeScroll: true,
      wrapAround: true,
      autoPlay: 1500,
      pauseAutoPlayOnHover: false,
      contain: true
    });
  }

  private cardHover() {
    if (!this.dataService.Destinationsfilled.value) { return; }
    if (this.IsCardHoverApplied) { return; }
    setTimeout(() => {
      const card = $('.post-module');
      if (card == null || card.length < 1) { return; }
      card.hover(function() {
        $(this).find('.description').stop().animate({
          height: 'toggle',
          opacity: 'toggle'
        }, 400, 'linear');
      });
    }, 500);
    this.IsCardHoverApplied = true;
  }

  GetDomesticPlaces() {
    if(this.places == null || this.places.length < 1) {
      this.places = this.dataService.GetDomesticPlaces();
    }
  }
  GetTourPackages(){
    if(this.hometourpackages == null || this.hometourpackages.length < 1) {
      this.hometourpackages = this.dataService.GetTourPackages();
    }
  }

}
