import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private idCounter = 0;
  Toasts: Array<{id: number, heading: string, message: string}> = [];
  constructor() {}

  Show(heading: string, message: string, timeout: number = 0) {
    this.idCounter++;
    const idVal = this.idCounter;
    this.Toasts.unshift({
      id: idVal,
      heading,
      message
    });
    // this.Toasts = [{
    //   id: idVal,
    //   heading,
    //   message
    // }];
    if (timeout > 0) {
      setTimeout(() => {
        this.onCloseToast(idVal);
      }, timeout);
    }
  }

  onCloseToast(id: number) {
    const index = this.Toasts.findIndex((val) => {
      return val.id === id;
    });
    if (index >= 0 && this.Toasts.length > index) {
      this.Toasts.splice(index, 1);
    }
  }
}
