import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { LoginComponent } from './shared/components/login/login.component';
import { HomeComponent } from './core/components/home/home.component';
import { IndexPage } from './modules/website/pages/index/index.page';
import { AboutPage } from './modules/website/pages/about/about.page';
import { ContactPage } from './modules/website/pages/contact/contact.page';
import { DesitnationListComponent } from './shared/components/desitnation-list/desitnation-list.component';
import { DestinationPage } from './modules/destination/pages/destination/destination.page';
import { DestinationsPage } from './modules/destination/pages/destinations/destinations.page';
import { MicePage } from './modules/website/pages/mice/mice.page';

const routes: Routes = [
  { path: 'login', component: LoginComponent }
, { path: 'destinationlist', component: DesitnationListComponent }
, { path: 'notFound', component: NotFoundComponent }
, {
    path: '',
    component: HomeComponent,
    children: [
      {path: 'index', component: IndexPage }
      , {path: 'about', component: AboutPage }
      , {path: 'contact', component: ContactPage }
      , {path: 'destination/:id', component: DestinationPage }
      , {path: 'destinations/:id', component: DestinationsPage }
      , {path: 'mice', component: MicePage }
      , { path: '', redirectTo: 'index', pathMatch: 'full' }
    ]
  }
, { path: '**', redirectTo: 'notFound', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
    // scrollOffset: [0, 64] // [x, y])
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
