import { Destination } from './Destination.model';

export class Place {
    public PlaceId: number;
    public Destinations: Array<Destination>;
    public Name: string;
    public SubHeading?: string;
    public Places?: Array<{ Name?: string, Description?: string }>;
    public Description?: string;
    constructor(data?: any) {
        Object.assign(this, data);
        if (data && data.Destinations) {
            this.Destinations = data.Destinations.map(d => new Destination(d));
        }
    }
    get GetSubHeading(): string {
        if (this.SubHeading == null || this.SubHeading.trim() === '' ) {
          return `Top Places to Visit in ${this.Name}`;
        } else{
            // return this.SubHeading;
            return `Top Places to Visit in ${this.Name}`;
        }
    }

    get ShowPlaces(): boolean {
        if ( this.Places == null || this.Places.length < 1 ) {
            return false;
        } else {
            return true;
        }
    }

    get GetPlaceImagePath(): string {
        return `assets/img/places/${this.PlaceId}/card.jpg`;
    }
}
