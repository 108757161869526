import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Destination } from '../../models/Destination.model';
import { DataService } from '../../services/data.service';
import { SessionService } from '../../services/session.service';
import { ToastService } from '../toast/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-desitnation-list',
  templateUrl: './desitnation-list.component.html',
  styleUrls: ['./desitnation-list.component.scss']
})
export class DesitnationListComponent implements OnInit {
  destinations: Array<Destination>;
  constructor(
    private dataService: DataService,
    private apiService: ApiService,
    private session: SessionService,
    private toast: ToastService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.session.IsActive()) {
      this.router.navigateByUrl('/login');
    }
    this.dataService.Destinationsfilled.subscribe( val => {
        this.destinations = this.dataService.Destinations;
      }
    );
  }

  onSave(id, val1, val2) {
    let dest = this.dataService.GetDestinationById(id);
    dest.OffSeasonPrice = val1;
    dest.SeasonalPrice = val2;
    dest = new Destination(dest);
    this.apiService.UpdatePrice(dest).subscribe(val => {
      this.toast.Show('Success!', 'Updated successfully!', 10000);
    }, err => {
      this.toast.Show('Sorry!', 'Please login again.', 10000);
      this.session.Close();
    });

  }

}
