import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/shared/services/title.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-mice',
  templateUrl: './mice.page.html',
  styleUrls: ['./mice.page.scss']
})
export class MicePage implements OnInit {

  constructor(private title: Title
            , private titleService: TitleService) { }

  ngOnInit() {
    this.title.setTitle(`Mice | ` + this.titleService.siteName );
  }

}
