import { Directive, OnInit, ElementRef, Renderer2, HostListener, Input } from '@angular/core';
import { ReturnStatement } from '@angular/compiler';

@Directive({
  selector: '[parallax]'
})
export class ParallaxDirective implements OnInit {
    private previousScroll: number;
    @Input() Displacement = 1;
    @HostListener('window:scroll', ['$event']) onscroll(event: Event) {
        const isScrollDown = this.getScrollDirection();
        let backPosX = this.getElementPosition();
        if (isScrollDown) {
            backPosX -= this.Displacement;
            this.renderer.setStyle(this.elRef.nativeElement, 'background-position', backPosX + 'px');
        } else {
            backPosX += this.Displacement;
            this.renderer.setStyle(this.elRef.nativeElement, 'background-position', backPosX + 'px');
        }
    }
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }

    private getElementPosition() {
        return +(this.elRef.nativeElement.style.backgroundPositionX.replace('px', ''));
    }
    private getScrollDirection() {
        if (!this.isInView) { return; }
        console.log('Hit');
        let val: boolean;
        if (this.previousScroll < window.pageYOffset) {
            val = true;        // True means scroll down
        } else {
            val = false;
        }
        this.previousScroll = window.pageYOffset;
        return val;
    }
    get isInView(): boolean {
        const elemTop = this.elRef.nativeElement.offsetTop;
        const elemBottom = elemTop + this.elRef.nativeElement.offsetHeight;
        const winTop = window.pageYOffset;
        const winBottom = winTop + window.innerHeight;

        return elemTop <= winBottom && elemBottom >= winTop;
    }

  ngOnInit() {
    this.previousScroll = window.pageYOffset;
  }

//   @HostListener('mouseleave') mouseleave(data: Event){
//     this.renderer.setStyle(this.elRef.nativeElement, 'background-color', 'transparent');
//   }
}