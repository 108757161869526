import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Destination } from 'src/app/shared/models/Destination.model';
import { ActivatedRoute } from '@angular/router';
import { Place } from 'src/app/shared/models/Place.model';
import { Title } from '@angular/platform-browser';
import { TitleService } from 'src/app/shared/services/title.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { DataService } from 'src/app/shared/services/data.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.page.html',
  styleUrls: ['./destinations.page.scss']
})
export class DestinationsPage implements OnInit, AfterViewInit, OnDestroy  {
  // private destinations: Array<Destination>;
  public place = new Place();
  private dataServiceSub = new Subscription();
  constructor(
      private route: ActivatedRoute
    , private title: Title
    , private titleService: TitleService
    , private dataService: DataService
    ) { }

    ngAfterViewInit() {
    }

  ngOnInit() {
    const snapId = this.route.snapshot.params['id'];
    this.dataServiceSub = this.dataService.Placesfilled.subscribe(val => {
      this.Update(snapId);
      this.title.setTitle( this.place.Name + ` | ` + this.titleService.siteName );
    });
    this.Update(snapId);
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.Update(id);
      this.cardHover();
    });
    // this.cardHover();
  }
  ngOnDestroy() {
    this.dataServiceSub.unsubscribe();
  }

  private Update(snapId: any) {
    if (snapId != null) {
      this.GetPlace(+snapId);
      // this.titleService.setTitle(`${this.series.Name} | Muscle Science`);
    }
  }

  private cardHover() {
    setTimeout(() => {
      const card = $('.post-module');
      if (card == null || card.length < 1) { return; }
      card.hover(function() {
        $(this).find('.description').stop().animate({
          height: 'toggle',
          opacity: 'toggle'
        }, 400, 'linear');
      });
    }, 500);
  }

  GetPlace(id) {
      if (id == null) { return; }
      const p = this.dataService.GetPlaceById(id);
      if (p == null) {
        this.place = new Place();
      } else {
        this.place = p;
      }
  }
}
