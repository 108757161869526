import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private key = 'token-key';
    private token: string = null;
    get Token(): string {
        return this.token;
    }
    constructor(private router: Router) {
        const localstring = localStorage.getItem(this.key);
        if (localstring == null || localstring.trim() === '') { return; }
        this.token = localstring;
    }

    SaveToken(token: string): void {
        if (token == null) { return; }
        this.token = token;
        localStorage.setItem(this.key, this.token);
    }

    Close() {
      this.token = null;
      localStorage.removeItem(this.key);
      this.router.navigate(['/login']);
    }

    IsActive(): boolean {
        if (this.token == null || this.token.trim() === '') {
            return false;
        }
        return true;
    }
}
