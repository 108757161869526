import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsiteRoutingModule } from './website-routing.module';
import { IndexPage } from './pages/index/index.page';
import { SharedModule } from 'src/app/shared/shared.module';
import { AboutPage } from './pages/about/about.page';
import { ContactPage } from './pages/contact/contact.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MicePage } from './pages/mice/mice.page';

@NgModule({
  declarations: [
    IndexPage,
    AboutPage,
    ContactPage,
    MicePage
  ],
  imports: [
    CommonModule
    , SharedModule
    , WebsiteRoutingModule
    , FormsModule
    , ReactiveFormsModule
  ],
  exports: [
    WebsiteRoutingModule
  ]
})
export class WebsiteModule { }
