import { isArray } from 'util';

export class Destination {
    public DestinationId: number;
    public PlaceId: number;
    public Heading: string;
    public CardImagePath: string;
    public SliderImagesPaths: Array<string>;
    public Description: string;
    public Duration: string;
    public Routes: Array<any>;
    public Itineraries: Array<{ Heading?: string, Description?: string }>;
    public SeasonalPrice: string;
    public OffSeasonPrice: string;
    public StarCount: number;
    public SliderImagesCount: number;
    constructor(data?: any) {
        Object.assign(this, data);
    }

    get GetStarCountArray() {
        if (this.StarCount === null || this.StarCount === 0) {
            this.StarCount = 5;
        }
        return new Array<number>(this.StarCount);
    }

    get GetRoute() {
        let routeString = '';
        if (this.Routes !== null && isArray(this.Routes)) {
            this.Routes.forEach((val, index) => {
                if (index < this.Routes.length - 1) {
                    routeString +=  val.RouteName + ` - `;
                } else {
                    routeString += val.RouteName;
                }
             });
        }
        if (routeString === '') {
            return 'N/A';
        } else {
            return routeString;
        }
    }
    get GetCardImagePath() {
        return `assets/img/places/${this.PlaceId}/${this.DestinationId}/card.jpg`;
    }

    get GetSliderImagesPaths() {
        let list = [];
        if (this.SliderImagesCount === 0) { return list };
        for (let coun = 0; coun <= this.SliderImagesCount - 1; coun++){
            list.push(`assets/img/places/${this.PlaceId}/${this.DestinationId}/slider_${coun + 1}.jpg`.toString());
        }
        return list;
    }

}
