import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Place } from '../models/Place.model';
import { Destination } from '../models/Destination.model';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private destinations: Array<Destination> = [];
  private places: Array<Place> = [];
  public Placesfilled = new BehaviorSubject<boolean>(false);
  public Destinationsfilled = new BehaviorSubject<boolean>(false);

  get Destinations() {
    return this.destinations.slice();
  }
  get Places() {
    return this.places.slice();
  }

  constructor(private api: ApiService) {
      this.FillData();
      this.Placesfilled.subscribe((val: any) => {
      this.GetDomesticPlaces();
    });
  }

  public GetPlaceById(id: number) {
    if (!this.places || this.places.length < 1) { return null; }
    const place = this.places.find(val => val.PlaceId === id);
    if (place == null) { return null; }
    return place;
  }

  GetDestinationsByPlaceId(id: number) {
    let array = [];
    if (this.destinations == null) { return []; }
    array = this.destinations.filter((val) => {
        return val.PlaceId === id;
    });
    return array == null ? [] : array;
  }

  GetDestinationById(id: number) {
    let dest: Destination;
    if (this.destinations !== null) {
      dest = this.destinations.find(
        (val) => {
          return val.DestinationId === id;
        }
      );
      return dest;
    }
  }

  GetDomesticPlaces() {
    if(this.places == null) {return;}
    var newArray = this.places.filter(val => {
      return val.PlaceId < 10;
    });
    return newArray;
  }

  GetTourPackages() {
    if(this.destinations == null) {return;}
    var newArray = this.destinations.filter(val => {
      return val.DestinationId == 10 || val.DestinationId == 12 || val.DestinationId == 19  ;
    });
    return newArray;
  }


  public FillData() {
    this.api.GetPlaces().subscribe((res: any) => {
      // console.log(res);
      this.places = res;
      this.Placesfilled.next(true);
    });
    this.api.GetDestinations().subscribe((res: any) => {
      // console.log(res);
      this.destinations = res;
      this.Destinationsfilled.next(true);
    });

  }
}
