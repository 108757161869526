import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss']
})
export class AboutPage implements OnInit {

  constructor(
      private title: Title
    , private titleService: TitleService) { }

  ngOnInit() {
    this.title.setTitle(`About | ` + this.titleService.siteName );
  }

}
