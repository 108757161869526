import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Destination } from '../models/Destination.model';
import { Place } from '../models/Place.model';
import { map } from 'rxjs/operators';
import { Login } from '../models/Login.model';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient,
              private session: SessionService) {}

  basePath = `https://api.navratnaholidays.com/`;

  public GetDestinations() {
    return this.http.get<Destination[]>(`${this.basePath}api/Destinations/Getall`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map(dests => {
      return dests.map(d => new Destination(d));
    }));
  }

  public GetPlaces() {
    return this.http.get<Place[]>(`${this.basePath}api/Places/Getall`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(map(places => {
      return places.map(p => new Place(p));
    }));
  }

  public Login(details: Login) {
    return this.http.post<any>(`${this.basePath}api/Login/Login`, details, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
      // , withCredentials: true
      // , observe: 'response' as 'response'
    }).pipe(map((val: any, ind) => {
      if (val != null && val.status) {
        this.session.SaveToken(val.token);
      }
      return val;
    }));
  }

  public UpdatePrice(dest: Destination) {
    return this.http.post<any>(`${this.basePath}api/Destinations/Update`, dest, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.session.Token}`
      })
    }).pipe(map((val: any, ind) => {
      return val;
    }));
  }
}
