import { Component, OnInit, HostListener, OnDestroy, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { NavbarService } from '../../navbar.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  previousScrollValue = 0;
  displaySecondaryNav = false;
  displayPrimaryNav = false;
  displayHomeNav= false;
  private detectionValue = 280;
  isNotDesktop = false;
  ShowWhiteNav = false;
  // activatedRoute = '';
  NavColorSubc: Subscription;

  @ViewChildren('navCollapse') navCollapse: Array<ElementRef> = [];

  @HostListener('window:scroll', ['$event'])
   onWindowScroll(e: any){
    this.detectScrollUp();
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize(e: any){
   this.detectDesktop();
 }

  constructor(private navService: NavbarService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.previousScrollValue = window.pageYOffset;
    // this.setSecondNavDisplay();
    this.detectDesktop();
    this.detectScrollUp();
    this.NavColorSubc = this.navService.NavColorSub.subscribe(isWhiteNav => {
    this.ShowWhiteNav = isWhiteNav;
    });

    this.navService.Url.subscribe(url => {
      // this.activatedRoute = url;
      this.detectScrollUp();
    });

    $(function() {
      // ------------------------------------------------------- //
      // Multi Level dropdowns
      // ------------------------------------------------------ //
      $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function(event) {
        event.preventDefault();
        event.stopPropagation();

        $(this).siblings().toggleClass("show");


        if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
          $('.dropdown-submenu .show').removeClass("show");
        });

      });
    });
  }

  ngOnDestroy() {
    this.NavColorSubc.unsubscribe();
  }

  private setSecondNavDisplay() {
    if (window.pageYOffset > this.detectionValue) {
    } else {
    }
  }

  private detectScrollUp() {
    if (this.previousScrollValue > window.pageYOffset && window.pageYOffset > this.detectionValue) {
        this.displaySecondaryNav = true;
        this.displayHomeNav = false;
        this.displayPrimaryNav = false;
    } else {
      if (this.router.url.indexOf('index') > -1 || ( this.router.url.indexOf('/') === this.router.url.length - 1) ) {
        if(this.isNotDesktop) {
          this.displaySecondaryNav = false;
          this.displayHomeNav = false;
          this.displayPrimaryNav = true;
        } else {
          this.displaySecondaryNav = false;
          this.displayHomeNav = true;
          this.displayPrimaryNav = false;
        }
      } else {
        if (this.previousScrollValue > window.pageYOffset && window.pageYOffset > this.detectionValue) {
            this.displaySecondaryNav = true;
            this.displayHomeNav = false;
            this.displayPrimaryNav = false;
        } else {
          if(window.pageYOffset < this.detectionValue)
          {
            this.displaySecondaryNav = false;
            this.displayHomeNav = false;
            this.displayPrimaryNav = true;
          } else {
            this.displaySecondaryNav = false;
            this.displayHomeNav = false;
            this.displayPrimaryNav = false;
          }
        }
      }
    }
    this.previousScrollValue = window.pageYOffset;
  }

  private detectDesktop() {
    if (window.innerWidth < 991) {
      this.isNotDesktop = true;
    } else {
      this.isNotDesktop = false;
    }
  }

  onToggleSideNav() {
    this.navService.toggleNavSub.next();
  }

  closeNav() {
    this.navCollapse.forEach(element => {
      element.nativeElement.classList.remove('show');
    });
  }

  NavigateToTourPackage() {
    // this.router.navigateByUrl('/index');
    setTimeout(() => {
      this.navService.TourPackage.next();
    }, 100);
    this.closeNav();
  }

}
